// extracted by mini-css-extract-plugin
export var RecruitNow = "RecruitNow-module--RecruitNow--236f0";
export var RecruitNow__aside = "RecruitNow-module--RecruitNow__aside--7d548";
export var RecruitNow__asideBtn = "RecruitNow-module--RecruitNow__asideBtn--0d06d";
export var RecruitNow__asideWrapper = "RecruitNow-module--RecruitNow__asideWrapper--34690";
export var RecruitNow__badge = "RecruitNow-module--RecruitNow__badge--3268d";
export var RecruitNow__banner = "RecruitNow-module--RecruitNow__banner--e1868";
export var RecruitNow__button = "RecruitNow-module--RecruitNow__button--f8713";
export var RecruitNow__button__icon = "RecruitNow-module--RecruitNow__button__icon--ac05b";
export var RecruitNow__nav = "RecruitNow-module--RecruitNow__nav--73f69";
export var RecruitNow__navItem = "RecruitNow-module--RecruitNow__navItem--4a49f";
export var RecruitNow__pagination = "RecruitNow-module--RecruitNow__pagination--3bef0";
export var RecruitNow__select = "RecruitNow-module--RecruitNow__select--577e8";
export var cardFadeIn = "RecruitNow-module--cardFadeIn--6092e";