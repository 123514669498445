import React, { FunctionComponent, useState, forwardRef } from 'react'
import classNames from 'classnames'
import { Carousel, CarouselItem, Button, Row, Col } from '@goorm-dev/components'
import ChevronLeftIcon from '@goorm-dev/components/build/ChevronLeftIcon'
import ChevronRightIcon from '@goorm-dev/components/build/ChevronRightIcon'

import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'

import * as styles from './ResonForJoin.module.scss'

import { RESON_LIST } from '_constants/resonForJoinList'

type CarouselButtonsProps = {
  activeIndex: number
  listLength: number
  handlePrevious: React.MouseEventHandler<HTMLButtonElement>
  handleNext: React.MouseEventHandler<HTMLButtonElement>
}

const CarouselButtons: FunctionComponent<CarouselButtonsProps> = function ({
  activeIndex,
  listLength,
  handlePrevious,
  handleNext,
}) {
  return (
    <>
      {/* 웹뷰 버튼 */}
      <div className="d-lg-block d-none">
        <Button
          size="xl"
          color="basic"
          outline
          className={classNames(
            styles.ResonForJoin__controlBtn,
            styles.ResonForJoin__controlBtnLeft,
          )}
          onClick={handlePrevious}
        >
          <ChevronLeftIcon width={24} height={24} />
        </Button>
        <Button
          size="xl"
          color="basic"
          outline
          className={classNames(
            styles.ResonForJoin__controlBtn,
            styles.ResonForJoin__controlBtnRight,
          )}
          onClick={handleNext}
        >
          <ChevronRightIcon width={24} height={24} />
        </Button>
      </div>

      {/* 모바일뷰 버튼 */}
      <div
        className={classNames(
          'd-lg-none d-flex align-items-center',
          styles.ResonForJoin__controlBtnMobileWrapper,
        )}
      >
        <Button
          size="lg"
          color="basic"
          outline
          className={classNames(
            styles.ResonForJoin__controlBtn,
            styles.ResonForJoin__controlBtnLeft,
          )}
          onClick={handlePrevious}
        >
          <ChevronLeftIcon width={24} height={24} />
        </Button>
        <p className="subtitle-1 text-gray-600">
          {activeIndex + 1} / {listLength}
        </p>
        <Button
          size="lg"
          color="basic"
          outline
          className={classNames(
            styles.ResonForJoin__controlBtn,
            styles.ResonForJoin__controlBtnRight,
          )}
          onClick={handleNext}
        >
          <ChevronRightIcon width={24} height={24} />
        </Button>
      </div>
    </>
  )
}

const ResonForJoin: FunctionComponent = function (_, ref) {
  const [animating, setAnimating] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const handleNext = () => {
    if (animating) return
    const nextIndex =
      activeIndex === RESON_LIST.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }
  const handlePrevious = () => {
    if (animating) return
    const nextIndex =
      activeIndex === 0 ? RESON_LIST.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }
  const handleIndicators = e => {
    if (animating) return
    const { id } = e.target
    setActiveIndex(Number(id))
  }

  return (
    <SectionWrapper
      ref={ref}
      className={classNames(styles.ResonForJoin, 'bg-gray-50')}
    >
      <SectionHeader>
        <h2 className="text-center">
          수많은 기업을 제치고 구름에
          <br className="d-sm-none d-block" /> 합류한 이유
        </h2>
      </SectionHeader>

      <article>
        <Carousel
          activeIndex={activeIndex}
          next={handleNext}
          previous={handlePrevious}
          interval={false}
          className={styles.ResonForJoin__carousel}
        >
          <ol
            className={classNames(
              'carousel-indicators d-lg-flex d-none',
              styles.ResonForJoin__indicators,
            )}
          >
            {RESON_LIST.map((_, index) => {
              return (
                <li
                  key={index}
                  id={String(index)}
                  onClick={handleIndicators}
                  className={classNames({
                    'bg-gray-900': activeIndex === index,
                    'bg-gray-300': activeIndex !== index,
                  })}
                />
              )
            })}
          </ol>
          {RESON_LIST.map(item => (
            <CarouselItem
              key={item.key}
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              className={styles.ResonForJoin__item}
            >
              <Row className="d-flex flex-xs-column flex-row justify-content-center">
                <Col
                  lg={5}
                  sm={6}
                  xs={12}
                  className="d-flex align-items-center"
                >
                  <div
                    className={classNames(
                      styles.ResonForJoin__itemImage,
                      'bg-gray-100',
                    )}
                  >
                    <img src={item.src} />
                  </div>
                </Col>
                <Col
                  lg={7}
                  sm={6}
                  xs={12}
                  className="d-flex flex-column justify-content-center"
                >
                  <h6 className={classNames("text-gray-800 mb-3 pr-lg-0 pr-md-4 pr-sm-3 pr-0", styles.ResonForJoin__desc)}>
                    {item.desc}
                  </h6>
                  <p className="text-gray-500">{item.name}</p>
                </Col>
              </Row>
            </CarouselItem>
          ))}
          <CarouselButtons
            activeIndex={activeIndex}
            listLength={RESON_LIST.length}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        </Carousel>
      </article>
    </SectionWrapper>
  )
}

export default forwardRef(ResonForJoin)
