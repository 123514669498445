import * as React from 'react'
import cx from 'classnames'
import { SquadSlideType, SquadCardType } from './SquadSlide.types'
import * as styles from './Slide.module.scss'
import { SquadCarousel } from '../SquadCarousel/SquadCarousel'
import Fade from '_components/Common/Fade/Fade'

type SlideProps = SquadSlideType & { className?: string; dark?: boolean }

export const Slide: React.FC<SlideProps> = ({
  squad = 'Squad',
  image,
  squadCards = [
    {
      header: 'Header',
      desc: 'Desc',
    },
  ],
  className,
  dark = false,
}) => {
  const slideClasses = dark
    ? cx([styles.SlideDark, className])
    : cx([styles.Slide, className])
  const indicatorClasses = dark
    ? cx([styles.SlideDark__indicator, className])
    : cx([styles.Slide__indicator, className])

  return (
    <SquadCarousel
      className={slideClasses}
      indicatorClassName={indicatorClasses}
      slide={false}
      interval={false}
      squadCards={squadCards}
      image={image}
    />
  )
}
