export const RESON_LIST = [
  {
    key: '0',
    name: 'Frontend Engineer, Zero',
    desc: '면접 경험이 매우 좋았어요. 면접 내내 편안한 분위기를 만들어 주셨고 대답하기 어려운 질문은 구글링하면서 진행할 수 있었어요. 기술 면접은 토론 형식으로 진행되었는데 제가 생각한 해결 방안에 대해서 충분히 존중해 주시는 모습을 보고 합류하기로 결심했습니다. 한 시간 남짓한 면접 시간 동안 정말 많은 것을 배웠거든요.',
    src: '/assets/recruit-resonforjoin-zero.png',
  },
  {
    key: '1',
    name: 'Fullstack Engineer, Rosa',
    desc: '구름에서 인턴으로 근무하면서 내 손으로 프로덕트를 만들어 간다는 뿌듯함을 느꼈어요. 개발에 몰입할 수 있는 업무 환경을 지원해 주는 점도 좋았고요. 학생 때는 몰랐던 개발의 즐거움을 알게 되니 구르미 합류를 제안받았을 때 고민할 필요가 없었어요. 지금은 어엿한 1명의 풀스택 개발자로 매일 폭풍 성장 중입니다.',
    src: '/assets/recruit-resonforjoin-rosa.png',
  },
  {
    key: '2',
    name: 'Infrastructure Engineer, Grey',
    desc: '순수하게 구름의 인프라 아키텍처가 궁금했어요.  EDU, DEVTH, IDE 등 제품이 다양한 만큼 많은 경험을 할 수 있을 것 같았고, 실제로도 정말 다양한 경험을 하고 있는 중입니다.  스스로 주도적이고 적극적으로 아키텍처 디자인을 해볼 수 있겠다고 판단해 합류하게 되었어요.',
    src: '/assets/recruit-resonforjoin-grey.png',
  },
  {
    key: '3',
    name: 'Fullstack Engineer, Paul',
    desc: '학교와 군대에서 구름IDE를 정말 유용하게 사용했던 경험이 있었어요. 프로덕트에 대한 경험 자체가 좋았기 때문에 꼭 합류하고 싶었습니다. 국내 유일한 클라우드 IDE를 만들 수 있다는 기대감도 컸고요. 저의 사용 경험을 바탕으로 구름IDE를  더 발전시키고 싶었어요. 지금은 그때 바람이 이뤄져서 IDE 스쿼드에서 협업 기능과 모바일 사용성 개선을 중심으로 개발하고 있습니다.',
    src: '/assets/recruit-resonforjoin-paul.png',
  },
]
