import BizopsPic from '../../../images/Recruit/bizops-pic.png'
import CommPic from '../../../images/Recruit/comm-pic.png'
import ContentsPic from '../../../images/Recruit/contents-pic.png'
import DesignPic from '../../../images/Recruit/design-pic.png'
import DevopsPic from '../../../images/Recruit/devops-pic.png'
import OperationPic from '../../../images/Recruit/operation-pic.png'
import { TeamInfoType, TeamnameType } from './CardSlider.types'

const cardSliderInfo: {
  [key in TeamnameType]: TeamInfoType
} = {
  DESIGN: {
    teamname: 'DESIGN',
    header: '구름의 총체적 경험을\n디자인해요',
    content: `디자인팀은 구름의 플랫폼, 비즈니스, 브랜드, 사용자 경험을 그리는 팀입니다. 고객이 느끼는 총체적 경험을 디자인하죠. 사업 방향성과 고객의 니즈를 이해하여 경쟁력 있는 프로덕트를 설계합니다. 또한 일관된 브랜드 경험을 할 수 있도록 핵심 가치를 정립하고 '구름다움'을 고민합니다.`,
    image: DesignPic,
  },

  COMMUNICATION: {
    teamname: 'COMMUNICATION',
    header: '구름의\n이야기를 담아요',
    content:
      '커뮤니케이션팀은 구름의 플랫폼과 비즈니스, 브랜드 메시지를 구축하고 고객과 소통합니다. 성장을 위해서라면 마케팅, 홍보, 브랜딩, 그로스 해킹, 콘텐츠 등 수단과 방법을 가리지 않고 시도합니다. 성장 DNA가 타고난 사람들이 모여 있는 팀에서 자유롭게 질문하고, 함께 생각을 공유하며, 배우고 싶다면 지금 합류하세요!',
    image: CommPic,
  },

  BIZOPS: {
    teamname: 'BIZOPS',
    header: '전략 수립에서\n운영까지',
    content:
      '비즈옵스팀은 구름의 모든 비즈니스 영역에서 필요한 의사결정과 문제 해결을 돕는 팀입니다. 사업이 원활하게 돌아가는 데 필요한 업무들을 하고 있습니다. 외부 파트너들과의 계약 및 서비스 운영 관련 커뮤니케이션과 프로세스를 주로 관리하고 있습니다. 비즈니스를 기획하고 주도하는 높은 실행력을 갖추고 계신 분을 찾고 있습니다.',
    image: BizopsPic,
  },

  CONTENTS: {
    teamname: 'CONTENTS',
    header: '교육 콘텐츠를\n만들어요',
    content:
      '콘텐츠팀은 새로운 강의자를 발굴하고 신규 강좌를 만드는 과정 전반을 담당합니다. 커리큘럼 기획부터 제작, 오픈까지. 강의자님과 긴밀하게 소통하며 일합니다. 멘토링, 웨비나, 해커톤 등 다양한 형태의 프로그램을 설계하고 운영합니다.',
    image: ContentsPic,
  },

  OPERATION: {
    teamname: 'OPERATION',
    header: '즐거운 구름 생활을\n책임져요',
    content:
      '오퍼레이션팀은 인사, 회계, 법무 등 구름의  살림 전반을 맡고 있습니다. 구르미들의 즐거운 구름 생활을 위한 사내 문화와 정책을 기획하고 고민합니다. 회사에 대한 구르미들의 만족도를 높이는 것이 목표인 팀이죠. 구름 내 모든 활동과 업무가 원활하게 이루어질 수 있도록 지원합니다.',
    image: OperationPic,
  },

  DEVOPS: {
    teamname: 'DEVOPS',
    header: '보이는 곳부터 보이지 않는\n곳까지 만들어요',
    content:
      '데브옵스팀은 구름의 모든 것을 개발하고 운영하는 팀입니다. 구름의 개발 문화를 리드하는 팀이기도 하죠. 개발에 있어서는 직급과 연차에 상관없이 구름을 함께 만들어가는 동료라고 여깁니다. 모든 개발자가 모여 지식과 경험을 공유하는 회고 시간을 정기적으로 갖고 있습니다.',
    image: DevopsPic,
  },
}

const images = {
  bizops: BizopsPic,
  comm: CommPic,
  contents: ContentsPic,
  design: DesignPic,
  devops: DevopsPic,
  operation: OperationPic,
}

export { images, cardSliderInfo }
