import React, { forwardRef } from 'react'
import * as styles from './ShallWeGoorm.module.scss'
import Responsive from '_components/Common/Responsive/Responsive'
import { Button } from 'reactstrap'
import { RecruitNowNodeType } from '_types/Recruit.types'
import ScrollHint from '_components/Common/ScrollHint/ScrollHint'

type RecruitNowProps = {
  recruitNowList: RecruitNowNodeType[]
}

const ShallWeGoorm: React.FC<RecruitNowProps> = ({ recruitNowList }, ref) => {
  const shallWeGoormRef = React.useRef<HTMLDivElement>(null)
  const headerRef = React.useRef<HTMLDivElement>(null)
  const paragraphTopRef = React.useRef<HTMLDivElement>(null)
  const paragraphBottomRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (shallWeGoormRef.current instanceof HTMLElement) {
      shallWeGoormRef.current.style.transition = 'transform 1.4s ease-out'
      shallWeGoormRef.current.style.transform = 'scale(1.0)'
    }
    if (headerRef.current instanceof HTMLElement) {
      headerRef.current.style.transition =
        'transform 1s ease-out, opacity 0.6s ease-out 0.4s'
      headerRef.current.style.opacity = '1'
      // headerRef.current.style.transform = 'translateY(0px) scale(1.0)';
      headerRef.current.style.transform = 'translateY(0px)'
    }
    if (paragraphTopRef.current instanceof HTMLElement) {
      paragraphTopRef.current.style.transition =
        'transform 1s ease-out 0.2s, opacity 0.4s ease-out 0.4s'
      paragraphTopRef.current.style.transform = 'translateY(0px)'
      // paragraphTopRef.current.style.transform = 'translateY(0px) scale(1.0)';
      paragraphTopRef.current.style.opacity = '1'
    }
    if (paragraphBottomRef.current instanceof HTMLElement) {
      paragraphBottomRef.current.style.transition =
        'transform 1s ease-out 0.2s, opacity 0.4s ease-out 0.4s'
      paragraphBottomRef.current.style.transform = 'translateY(0px)'
      // paragraphBottomRef.current.style.transform = 'translateY(0px) scale(1.0)';
      paragraphBottomRef.current.style.opacity = '1'
    }
    if (buttonRef.current instanceof HTMLElement) {
      buttonRef.current.style.transition =
        'transform 1s ease-out 0.2s, opacity 1s ease-out 0.4s'
      buttonRef.current.style.transform = 'translateY(0px)'
      // buttonRef.current.style.transform = 'translateY(0px) scale(1.0)';
      buttonRef.current.style.opacity = '1'
    }
  })

  return (
    <div ref={ref} className={styles.ShallWeGoorm}>
      <div className={styles.ShallWeGoorm__image} ref={shallWeGoormRef} />
      <div className={styles.ShallWeGoorm__inner}>
        <h1 className={styles.BigHeader} ref={headerRef}>
          {'함께 성장할 \n뉴-구르미를 찾습니다'}
        </h1>
        <p ref={paragraphTopRef}>
          최고의 복지는 동료라고 합니다.
          <br />
          구름의 자부심은 바로 탁월한 구르미들입니다.
          <br />
          <br />
        </p>
        <p ref={paragraphBottomRef}>
          멋진 동료들과 함께 일하며 성장하고 싶다면,
          <br />
          지금 바로 구름에 합류하세요.
          <br />
        </p>
        <Responsive
          xs={
            <div ref={buttonRef} className={styles.Button}>
              <Button
                size="lg"
                color="primary"
                onClick={() => {
                  window.location.href = '#recruit-now'
                }}
              >
                구름은 지금 {recruitNowList?.length}개 직군 채용 중!
              </Button>
            </div>
          }
          def={
            <div ref={buttonRef} className={styles.Button}>
              <Button
                size="xl"
                color="primary"
                onClick={() => {
                  window.location.href = '#recruit-now'
                }}
              >
                구름은 지금 {recruitNowList?.length}개 직군 채용 중!
              </Button>
            </div>
          }
        />
      </div>
      <ScrollHint />
    </div>
  )
}

export default forwardRef(ShallWeGoorm)
