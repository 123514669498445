import { SquadType, SquadSlideType } from './SquadSlide.types'
import DevthCardImage from '../../../../../images/Recruit/devth.svg'
import EduBizCardImage from '../../../../../images/Recruit/edu-biz.svg'
import EduChannelCardImage from '../../../../../images/Recruit/edu-channel.svg'
import EduPublicCardImage from '../../../../../images/Recruit/edu-public.svg'
import PlatformBizCardImage from '../../../../../images/Recruit/platform-biz.svg'
import IdeCoreCardImage from '../../../../../images/Recruit/ide-console.svg'
// import GxCardImage from '../../../../../images/Recruit/gx.svg'
// import IdeConsoleCardImage from '../../../../../images/Recruit/ide-console.png';
// import IdeCoreCardImage from '../../../../../images/Recruit/ide-core.svg'

const platformBiz: SquadSlideType = {
  squad: 'PLATFORM BIZ',
  image: PlatformBizCardImage,
  squadCards: [
    {
      header: '고객의,\n고객에 의한, 고객을 위한',
      desc: `우리가 일하는 방식은 명확합니다. 모든 목표와 전략의 중심에는 고객이 있습니다. 학교와 기업, 기관과 협업하며 고객의 니즈를 발견하고 가장 적합한 솔루션을 제안합니다. 고객의 피드백을 최우선으로 '어떤 기능을 추가해야 할지', '무엇을 보완해야 할지' 결정합니다.`,
    },
    {
      header: '고객의 생애 주기에 맞춘 \n비즈니스 로드맵',
      desc: '초중고 및 대학생부터 취업 준비생, 직장인에 이르기까지. 고객의 생애 주기에 맞춰 성장 로드맵을 설계합니다. 우리가 추진하는 비즈니스의 목표는 고객의 성장입니다. 고객이 SW 지식을 배우고 개발자가 되는 여정을 함께하며 플랫폼 서비스 제공, 그 이상의 가치를 실현합니다.',
    },
    {
      header: '플랫폼의 가치를 키우고\n새로운 기회를 만들어요',
      desc: '비즈니스를 통해 플랫폼의 가치를 개발하고 수익 증대에 기여합니다. 파트너와의 관계를 발전시켜 새로운 시장 기회를 창출합니다. 스쿼드와 긴밀하게 소통하며 새로운 비즈니스 모델을 모색하는 것도 중요한 과업입니다.',
    },
  ],
}

const eduBizSlide: SquadSlideType = {
  squad: 'EDU BIZ',
  image: EduBizCardImage,
  squadCards: [
    {
      header: '최고의 가치는\n교육생의 성장',
      desc: '정부 기관, 기업과 함께 개발자 양성을 위한 SW 교육을 제공합니다. 기술 트렌드와 교육생의 니즈를 반영해 효과적인 교육 과정을 설계합니다. 온∙오프라인으로 교육생들과 직접 소통하며 효율적인 운영을 위해 힘쓰고 있습니다. 우리가 하는 일이 누군가의 인생을 변화시키는 일임을 알기에, 교육의 본질에 대해 생각합니다. 선발부터 수료까지 여정을 함께하며 교육생의 성장을 돕습니다.',
    },
    {
      header: '지속 가능한 \n성장을 위한 교육',
      desc: '기술이 발전하는 속도만큼 개발자에게 요구되는 직무 능력 수준도 빠르게 높아지고 있습니다. 개발자로 지속적으로 성장하기 위해서 스스로 학습하고 문제를 해결하는 능력이 무엇보다 중요합니다. 우리는 교육생이 꾸준히 성장할 수 있도록 자기주도학습과 문제 해결 중심의 교육 프로그램을 운영합니다.',
    },
    {
      header: '업무도\n자기주도적으로',
      desc: '교육생에게 자기주도학습의 중요성을 강조하기 위해서는 우리가 일하는 방식도 자기주도적이어야 한다고 생각합니다. 기본적으로 각자 스스로 할 수 있는 일을 찾아 실행합니다. 주간 회의를 통해 업무의 비효율적인 부분은 없는지 점검합니다. 문제점이 있다면 더 나은 방법을 찾기 위해 함께 고민합니다. 일을 위한 일이나 불필요한 업무는 하지 않습니다.',
    },
  ],
}

const eduPublicSlide: SquadSlideType = {
  squad: 'EDU PUBLIC',
  image: EduPublicCardImage,
  squadCards: [
    {
      header: '누구에게나 열린\nSW 교육 플랫폼',
      desc: '온라인 SW 교육 플랫폼 ‘구름EDU’를 만들고 있습니다. 우리의 고객은 강의자와 수강자입니다. 수강자는 편리하게 강좌를 수강하고, 강의자는 원하는 방식으로 가르칠 수 있도록 기능을 개선합니다. 우리가 만든 플랫폼을 통해 많은 분들이 개발 여정을 시작하고, 새로운 기회를 얻을 때 보람을 느낍니다.',
    },
    {
      header: '배움의\n진입 장벽 낮추기',
      desc: '여전히 "비전공자인 나도 할 수 있을까?"라는 고민을 하는 분들이 많습니다. 구름EDU에서는 전공 유무나 학력, 연령에 상관없이 SW 지식을 배울 수 있습니다. 개발자 커리어 여정을 처음 시작하는 분들이 겪는 어려움을 기술로 해결합니다. 누구나 양질의 교육 콘텐츠를 이용하고 성장의 기회를 누릴 수 있도록 배움의 진입 장벽을 낮춥니다.',
    },
    {
      header: '고객의 피드백을\n먹고 성장 중',
      desc: '온라인 교육 플랫폼 특성상 고객 반응이 즉각적입니다. 수강 후기, 1:1 상담, 강의자를 통해 다양한 피드백이 매일 들어옵니다. 피드백은 빠르게 받아들여 기획에 반영합니다. 이 과정을 3주 단위로 반복하면서 플랫폼을 개선하고 있습니다. 고객의 피드백 먹고 성장 중인 셈이죠.',
    },
    // {
    //   header: '모두가\n상생하는 생태계',
    //   desc: '프로그래밍 교육 플랫폼인 만큼 AI∙SW 지식과 경험을 쉽게 배우고 나눌 수 있는 환경을 구현하는 것이 중요한 과업이에요. 올해 개발 로드맵도 빡빡하죠. 스쿼드원 모두가 동의하고 잊지 않으려는 부분이 있다면, 바로 플랫폼의 본질! 합리적인 가격 정책을 기반으로 플랫폼과 강의자, 수강자 모두가 상생하는 구조를 만들려고 해요.',
    // },
  ],
}

const eduChannelSlide: SquadSlideType = {
  squad: 'EDU CHANNEL',
  image: EduChannelCardImage,
  squadCards: [
    {
      header: '올인원\nSW 교육 공간',
      desc: '구름EDU 채널은 이론과 실습, 시험 도구를 한데 모은 올인원 온라인 SW 교육 공간입니다. 우리의 핵심 고객은 SW 교육을 필요로 하는 기업과 기관입니다. 고객이 자신들만의 독립적인 공간(=채널)에서 효율적으로 교육하고 관리할 수 있도록 다양한 기능을 개발하고 있습니다.',
    },
    {
      header: '기술로 해결하는\nSW 교육의 어려움',
      desc: '구름EDU 채널은 교육 관리자, 강의자, 수강자 등 다양한 사용자들이 플랫폼을 이용합니다. 각각의 사용자가 SW 교육에서 겪고 있는 본질적인 어려움은 무엇일지 고민하고 기술로 해결합니다. 관리자는 운영의 효율을 높이고, 강의자는 원하는 형태의 수업을 진행하고, 수강자는 편리하게 학습할 수 있도록 최적의 환경을 제공합니다. ',
    },
    {
      header: '이왕 하는거\n제대로 하는 타입',
      desc: '시장을 압도하는 플랫폼을 만들기 위해서는 구성원의 역량이 매우 중요합니다. ‘이 분야에서는 우리가, 내가 최고가 되겠다’는 마음으로 일합니다. 플랫폼의 방향성과 개발 로드맵에 대해 많은 시간을 들여 고민하는 이유이기도 하죠. 우리가 만든 플랫폼이 고객에게 어떤 가치를 주는지 생각하는 마인드를 중요하게 여깁니다.',
    },
    // {
    //   header: '끊임없이\n진화하는 제품',
    //   desc: '이미 많은 고객사와 함께 하며 의미 있는 성과를 만들어 내고 있지만 여기서 멈추지 않으려고 해요. 더 많은 분들이 쉽고 편하게 채널을 도입하실 수 있도록 도입 방식을 더욱 멋지고 간편하게 만들 예정입니다. Zoom을 대체할 수 있을 만큼 강력한 화상 강의와 대시보드 기능을 기획하고 있어요. 올해는 콘솔 기능까지 만들어 배포하는 것을 목표로 삼고 있습니다.',
    // },
  ],
}

const devthSlide: SquadSlideType = {
  squad: 'DEVTH',
  image: DevthCardImage,
  squadCards: [
    {
      header: '채용 트렌드에 맞춘\n코딩 테스트 플랫폼',
      desc: '삼성, LG, 네카라쿠배당토 같은 기업 이외에도 개발자 채용 과정에 코딩 테스트가 필수인 경우가 많아지고 있습니다. 기업 자체적으로 코딩 대회를 열기도 하고요. 변화하는 개발자 채용 트렌드에 맞춰 코딩 테스트 플랫폼을 만들고 있습니다. 최근에는 부정행위 감독 기능을 필요로 하는 고객의 니즈를 반영해 실시간 화상 감독 서비스인 옵저뷰(Obserview)를 출시했습니다.',
    },
    {
      header: '인재 채용과\n커리어 성장 기회',
      desc: '코딩 테스트 플랫폼이 갖춰야 할 기본은 안정성과 공정성입니다. 우리는 기본에 충실함으로써 고객의 문제를 해결합니다. 기업에게는 지원자의 실력을 정확하게 검증해 인재 채용 기회를 제공하고, 지원자에게는 커리어 성장의 기회를 제공합니다.',
    },
    {
      header: '꾸준히\n빠른 속도로',
      desc: '모든 프로젝트는 3주 단위 스프린트로 진행합니다. 1주 차는 기획, 2주 차는 개발, 3주 차는 리뷰/QA에 집중합니다. 매주 1회 스크럼을 통해 스프린트 진행 상황을 공유합니다. 스프린트가 끝나면 회고를 통해 피드백을 주고받으며 로드맵을 되짚어봅니다. 로드맵은 고객 피드백을 반영해 설계하고 있습니다.',
    },
    // {
    //   header: '채용과\n커리어 여정을 함께',
    //   desc: '기업의 채용 여정과 응시자의 커리어 여정을 함께하는 서비스를 만들기 위해 다양한 시도를 하고 있습니다. 더 많은 비즈니스에서 구름DEVTH를 활용할 수 있도록 문제 출제 방식을 다양화할 계획이고요. 사용자는 평가 또는 응시에만 집중할 수 있도록 UI/UX 개선, 운영 환경 최적화를 진행할 예정입니다.',
    // },
  ],
}

const ideSlide: SquadSlideType = {
  squad: 'IDE',
  image: IdeCoreCardImage,
  squadCards: [
    {
      header: '개발 환경의\n새로운 패러다임',
      desc: '언제 어디서나 클라우드 자원을 활용해 자신의 아이디어를 마음껏 펼치는 세상을 만들고자 구름IDE를 개발하고 있습니다. 사용자들이 구름IDE를 통해 높은 개발 생산성을 경험할 수 있도록 다양한 환경을 지원하고 있습니다.',
    },
    {
      header: '프로그래밍에\n진심인 편',
      desc: '구름IDE를 만드는 우리는 프로그래밍에 누구보다 진심입니다. 궁금한 점은 언제든지 구성원과 자유롭게 논의하고 리뷰를 주고받으며 코드 퀄리티를 높이고 있습니다. 합류하시면 사용자 경험 개선을 위해 성능 최적화와 제품 안정성을 키우는 방법을 고민하게 될 거예요.',
    },
    {
      header: '제품과\n함께 성장 중',
      desc: '개인의 성장이 제품의 성장으로 이어지는 개발 문화를 만들어가고 있습니다. 각자의 경험은 모두의 경험적 자산이 될 수 있도록 공유합니다. 그동안 우리가 만든 모든 기능은 각자가 자신의 일을 ‘잘’하기 위해 고민하고 노력하는 과정에서 나온 결과물입니다.',
    },
    // {
    //   header: '누적 사용자 수\n200만을 향해',
    //   desc: '올해는 사용자에게 더 친숙하고 빠른 개발 경험을 제공하려고 해요. UI/UX 고도화는 물론이고 개발자들의 능률을 끌어올릴 수 있도록 지금보다 더 다양한 클라우드∙DevOps 환경도 지원할 예정입니다. 터미널을 독립적인 모듈로 개발해 제품화 계획도 준비 중입니다.',
    // },
  ],
}

// const ideConsoleSlide: SquadSlideType = {
//   squad: 'IDE CONSOLE',
//   image: IdeConsoleCardImage,
//   squadCards: [
//     {
//       header: '강력한\nDevOps 플랫폼',
//       desc: '사용자가 단순히 개발을 ‘잘’할 수 있도록 도와주는 것뿐만 아니라 개발에서 서비스 운영까지의 여정을 함께하며 사용자에게 최고의 개발 경험을 제공하기 위해 노력합니다. 컨테이너 기반의 새로운 기능을 만들거나 개선하는 작업을 주로 하고 있어요. 컨테이너를 이용하는 사용자들의 데이터를 활용해 사용자 중심 제품을 개발하고 있습니다.'
//     },
//     {
//       header: '쉽고 편리한\n개발 경험',
//       desc: '‘모두가 개발자가 된다!’는 구름의 미션을 실현하기 위해서 누구나 이해하고, 어려움 없이 사용할 수 있는 직관적인 UI와 UX를 고민해요. 다른 서비스를 이용해 만든 프로젝트도 구름IDE에서 이어서 진행할 수 있도록 외부 서비스 연동 기능과  컨테이너 관리 기능도 강화하고 있어요. 합류하시면 사용자 경험을 개선하면서 성능 최적화와 제품 안정성을 키우는 방법을 고민하게 될 거예요.'
//     },
//     {
//       header: '제품과\n함께 성장 중',
//       desc: '제품을 만드는 과정에 개발자뿐 아니라 다양한 직군이 참여해요. 개인의 성장은 곧 제품의 성장으로이어진다는 믿음을 가지고 각자의 분야에서 일을 ‘잘’할 수 있도록 함께 고민해요! 각자의 경험은 모두의 경험적 자산이 될 수 있도록 공유해요. 그동안 릴리즈된 수많은 기능은 업무 효율을 극대화하고 퍼포먼스를 최대치로 이끌어낼 수 있는 방법을 고민하고 시도하는 과정에서 나온 결과물입니다.'
//     },
//     {
//       header: '개발 생태계\n혁신이 목표',
//       desc: '우리의 목표 설정은 멋진 일을 상상해 보는 것에서부터 출발해요. 모두가 언제 어디서나 구름할 수 있는 세상. 이렇게 누구나 쉽게 개발을 시작하고, 자신이 만든 서비스를 배포하고 공유하며 함께 성장하는 유기적인 생태계를 만들어나가는 것이 목표입니다.'
//     }
//   ],
// };

// const goormExperienceSlide: SquadSlideType = {
//   squad: 'Goorm eXperience',
//   image: GxCardImage,
//   squadCards: [
//     {
//       header: '우리의 고객은\n구르미',
//       desc: '구르미들이 구름에서 생활하며 겪는 경험 자체를 제품이라고 생각해요. 비즈니스 관점에서 우리의 고객은 구르미들인 거죠. 채용부터 공간, 근무 환경, 문화, 복지, 제도 등 구르미들이 구름에서 겪게 될 모든 경험을 만들어가고 있어요. 최근에는 구름EXP 제도를 고도화하고 구르미들이 더 편리하게 이용할 수 있도록 서비스로 구현하는데 힘쓰고 있습니다.'
//     }
//   ],
// };

const squadSlideInfo: {
  [key in SquadType]: SquadSlideType
} = {
  'PLATFORM BIZ': platformBiz,
  'EDU PUBLIC': eduPublicSlide,
  'EDU CHANNEL': eduChannelSlide,
  'EDU BIZ': eduBizSlide,
  DEVTH: devthSlide,
  IDE: ideSlide,
  // 'IDE CONSOLE': ideConsoleSlide,
  // 'Goorm eXperience': goormExperienceSlide,
}

export default squadSlideInfo
