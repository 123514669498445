import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'

import Header from '_components/Common/Header/Header'
import PageWrapper from '_components/Common/PageWrapper/PageWrapper'

// import OpenRecruit from '_containers/Recruit/OpenRecruit/OpenRecruit'
import ShallWeGoorm from '_containers/Recruit/ShallWeGoorm/ShallWeGoorm'
import GoormStory from '_containers/Recruit/GoormStory/GoormStory'
import ResonForJoin from '_containers/Recruit/ResonForJoin/ResonForJoin'
import GoormeeNews from '_containers/Recruit/GoormeeNews/GoormeeNews'
import RecruitNow from '_containers/Recruit/RecruitNow/RecruitNow'
import RecruitFaq from '_containers/Recruit/RecruitFaq/RecruitFaq'
import TeamIntro from '_containers/Recruit/TeamIntro/TeamIntro'
// NOTE: 기획 변경으로 인한 '구르미 합류 여정' 섹션 잠시 fade-out
// import GoormJoin from '_containers/Recruit/GoormJoin/GoormJoin'

import useGridBreakpoint from '_hooks/useGridBreakpoint'

import { RecruitType } from '_types/Recruit.types'

type RecruitPageProps = RecruitType

const RecruitPage: FunctionComponent<RecruitPageProps> = function ({
  data: {
    recruitNow: { edges: recruitNowList },
    recruitFaq: { edges: recruitFaqList },
  },
}) {
  const sectionsRef = useRef<HTMLDivElement[]>(Array.from({ length: 5 }))
  const [headerColor, setHeaderColor] = useState<string>('dark')
  const currentBreakpoint: string = useGridBreakpoint([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ])

  useEffect(() => {
    let animationThrottling = false

    const onScroll = () => {
      if (animationThrottling) return
      animationThrottling = true

      const scrollY = window.pageYOffset
      const section1Height = sectionsRef.current[0]?.clientHeight
      const section2Height = sectionsRef.current[1]?.clientHeight
      const section3Height = sectionsRef.current[2]?.clientHeight
      const section4Height = sectionsRef.current[3]?.clientHeight
      const section5Height = sectionsRef.current[4]?.clientHeight

      requestAnimationFrame(() => {
        animationThrottling = false
      })

      // Section1 에 있음
      let sum = section1Height
      if (scrollY < sum) {
        setHeaderColor('dark')
        return
      }

      // Section 2-4 에 있음
      sum += section2Height + section3Height + section4Height
      if (scrollY < sum) {
        setHeaderColor('light')
        return
      }

      // Section5에 있음
      sum += section5Height
      if (scrollY < sum) {
        setHeaderColor('dark')
        return
      }

      // Section6 ~ Footer에 있음
      setHeaderColor('light')
    }
    document.addEventListener('scroll', onScroll, {
      passive: true,
    })
  }, [])

  // NOTE: 공채 기간에는 공채 전용 컴포넌트 OpenRecruit 를 대신 보여줌
  return (
    <PageWrapper Header={<Header color={headerColor} />}>
      <ShallWeGoorm
        recruitNowList={recruitNowList}
        ref={ref => {
          sectionsRef.current[0] = ref
        }}
      />
      {/* <OpenRecruit
        ref={ref => {
          sectionsRef.current[0] = ref
        }}
        currentBreakpoint={currentBreakpoint}
      /> */}
      <GoormStory
        ref={ref => {
          sectionsRef.current[1] = ref
        }}
      />
      <TeamIntro
        ref={ref => {
          sectionsRef.current[2] = ref
        }}
      />
      <ResonForJoin
        ref={ref => {
          sectionsRef.current[3] = ref
        }}
      />
      <GoormeeNews
        ref={ref => {
          sectionsRef.current[4] = ref
        }}
      />
      {
        // NOTE: 기획 변경으로 인한 '구르미 합류 여정' 섹션 잠시 fade-out
        // <GoormJoin />
      }
      <RecruitNow recruitNowList={recruitNowList} />
      <RecruitFaq recruitFaqList={recruitFaqList} />
    </PageWrapper>
  )
}

export default RecruitPage

export const getRecruitPageMarkdownData = graphql`
  query getRecruitPageMarkdownData {
    recruitNow: allStrapiRecruit(sort: { order: DESC, fields: [publishedAt] }) {
      edges {
        node {
          id
          title
          team
          squad
          link
          employment
          hashtag
          date: publishedAt
        }
      }
    }
    recruitFaq: allStrapiRecruitFaq(
      sort: { order: DESC, fields: [publishedAt] }
    ) {
      edges {
        node {
          id
          question
          date: publishedAt
          answerObj: answer {
            data {
              text: answer
            }
          }
        }
      }
    }
  }
`
