import * as React from 'react'
import cx from 'classnames'
import * as styles from './CardCarousel.module.scss'
import { TeamnameType } from '../../CardSlider.types'
import { cardSliderInfo } from '../../CardSlider.module'
import { Card } from '../Card/Card'
import { Carousel } from '_components/Common/Carousel/Carousel'

export const CardCarousel: React.FC = () => {
  const teamList: TeamnameType[] = [
    'DEVOPS',
    'DESIGN',
    'COMMUNICATION',
    'BIZOPS',
    'CONTENTS',
    'OPERATION',
  ]
  const cards = teamList.map((team, index) => {
    const cardInfo = cardSliderInfo[team]
    return <Card {...cardInfo} key={`carousel-card-${index}`} />
  })

  return (
    <div className={styles.CardCarousel}>
      <Carousel
        interval={false}
        indicatorClassName={styles.CardCarousel__indicator}
        className={styles.CardCarousel__carousel}
      >
        {cards}
      </Carousel>
    </div>
  )
}
