import React, { FunctionComponent } from 'react'
import { Card, CardBody } from '@goorm-dev/components'
import classNames from 'classnames'

import * as styles from './GoormeeCard.module.scss'
import SplitText from '_components/Common/SplitText/SplitText'

type GoormeeCardProps = {}

const GoormeeCard: FunctionComponent<GoormeeCardProps> = function ({
  href,
  src,
  text,
  backgroundColor,
}) {
  const bgColor: string = backgroundColor || styles['gray800']

  return (
    <span className={styles.GoormeeCard}>
      <a href={href} target="_blank">
        <Card
          className={classNames(styles.GoormeeCard__wrapper, 'bg-transparent')}
        >
          <header
            style={{
              backgroundColor: bgColor,
            }}
            className={styles.GoormeeCard__header}
          >
            <img src={src} alt="goormee card" />
          </header>
          <CardBody
            className={classNames(
              styles.GoormeeCard__body,
              'd-flex justify-content-center align-items-center',
            )}
          >
            <h6 className="text-white text-center">
              <SplitText text={text} />
            </h6>
          </CardBody>
        </Card>
      </a>
    </span>
  )
}

export default GoormeeCard
