import React, { forwardRef } from 'react'
import * as styles from './TeamIntro.module.scss'
import { Container } from '@goorm-dev/components'
import { CardSlider } from './components/CardSlider/CardSlider'
import Responsive from '_components/Common/Responsive/Responsive'
import { CardCarousel } from './components/CardCarousel/CardCarousel'
import HorizonScroll from './components/HorizonScroll/HorizonScroll'
import cx from 'classnames'

const Header: React.FC<{ className?: string }> = ({ className }) => (
  <div className={cx(styles.TeamIntro__header, className)}>
    <h2>
      이 분야 최고의
      <br />
      전문성을 갖춘 팀
    </h2>
  </div>
)

const TeamIntro: React.FC = (_, ref) => {
  return (
    <section ref={ref} className={styles.TeamIntro}>
      <Container fluid="lg">
        <Header />
      </Container>
      <Responsive
        // def={
        //   <div>
        //     <HorizonScroll
        //       // scrollY={scrollY}
        //       header={
        //         <Header className={styles.TeamIntro__headerSlide} />
        //       }>
        //       <div>
        //         <CardSlider style={{ overflowX: 'inherit' }} />
        //       </div>
        //     </HorizonScroll>
        //   </div>
        // }
        def={<CardSlider />}
        sm={
          <Container fluid="lg">
            <CardCarousel />
          </Container>
        }
      />
    </section>
  )
}

export default forwardRef(TeamIntro)
