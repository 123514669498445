import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { Button } from 'reactstrap'
import { Nav, NavItem, NavLink, Input, Badge } from '@goorm-dev/components'
import BasicPagination from '@goorm-dev/components/build/BasicPagination'
import SubmitModeIcon from '@goorm-dev/components/build/SubmitModeIcon'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import RecruitNowItems from '_components/Recruit/RecruitNowItems/RecruitNowItems'
import Responsive from '_components/Common/Responsive/Responsive'

import { RecruitNowNodeType, RecruitNowItemType } from '_types/Recruit.types'
import * as styles from './RecruitNow.module.scss'

type RecruitNowProps = {
  recruitNowList: RecruitNowNodeType[]
}

const ITEM_LIMIT = 10

enum TEAM_LIST {
  DEVOPS = 'DEVOPS',
  BIZOPS = 'BIZOPS',
  DESIGN = 'DESIGN',
  COMMUNICATION = 'COMMUNICATION',
  CONTENTS = 'CONTENTS',
  OPERATION = 'OPERATION',
}
enum TEAM_PRIORITY {
  DEVOPS = 1,
  BIZOPS = 2,
  DESIGN = 3,
  COMMUNICATION = 4,
  CONTENTS = 5,
  OPERATION = 6,
}
export enum EMPLOYMENT_KO {
  'all-employment' = '전체 고용 형태',
  'full-time' = '정규직',
  'part-time' = '계약직',
  'intern' = '인턴',
}

const RecruitNow: FunctionComponent<RecruitNowProps> = function ({
  recruitNowList,
}) {
  // aside(팀, 스쿼드, 고용형태) 관련 변수
  const [activeTab, setActiveTab] = useState<string>(TEAM_LIST['DEVOPS'])
  const [activeButton, setActiveButton] = useState<string>('ALL')
  const [activeSelect, setActiveSelect] = useState<string>('all-employment')

  // article(RecruitItems) 관련 변수
  const [teamItems, setTeamItems] = useState<[string, any][]>([
    [
      TEAM_LIST['DEVOPS'],
      {
        priority: 0,
        num: 0,
      },
    ],
  ])
  const [squadItems, setSquadItems] = useState<string[]>([activeButton])
  const [employmentItems, setEmploymentItems] = useState<string[]>([
    activeSelect,
  ])
  const [recruitItems, setRecruitItems] = useState<RecruitNowItemType[]>([
    {
      id: '',
      title: '',
      team: '',
      squad: '',
      link: '',
      employment: '',
      date: '',
      hashtag: '',
    },
  ])
  const filterList = recruitItems.filter(
    item =>
      item.team === activeTab &&
      (activeButton === 'ALL' || item.squad === activeButton) &&
      (activeSelect === 'all-employment' || item.employment === activeSelect),
  )

  //**  footer(Pagination) 관련
  const total = filterList.length
  const headerRef = useRef<HTMLDivElement>(null)
  const [page, setPage] = useState<number>(1)
  const [start, setStart] = useState<number>(0)
  const [end, setEnd] = useState<number>(ITEM_LIMIT)

  useEffect(() => {
    const teamMap = new Map<string, number>()
    const squadSet = new Set<string>()
    const employmentSet = new Set<string>()
    const recruitSet = new Set<RecruitNowItemType>()

    recruitNowList.forEach(
      ({
        node: { id, squad, team, date, title, employment, link, hashtag },
      }) => {
        if (teamMap.has(team)) {
          teamMap.set(team, {
            priority: TEAM_PRIORITY[team],
            num: teamMap.get(team)?.num + 1,
          })
        } else {
          teamMap.set(team, {
            priority: TEAM_PRIORITY[team],
            num: 1,
          })
        }

        squadSet.add(squad)
        employmentSet.add(employment)
        recruitSet.add({
          id,
          squad,
          team,
          date,
          title,
          employment,
          link,
          hashtag,
        })
      },
    )

    const tmp = [...teamMap].sort(
      (prev, next) => prev[1].priority - next[1].priority,
    )

    setActiveTab(tmp[0][0])
    setTeamItems(tmp)
    setSquadItems([...squadItems, ...squadSet])
    setEmploymentItems([...employmentItems, ...employmentSet])
    setRecruitItems([...recruitSet])
  }, [])

  const onInitPagination = () => {
    setPage(1)
    setStart(0)
    setEnd(ITEM_LIMIT)
  }
  const handleScrollMove = () => {
    headerRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleTab = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { id } = e.currentTarget
    setActiveTab(id)
    onInitPagination()
  }
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target

    setActiveSelect(value)
    onInitPagination()
  }
  // NOTE: 회사 정책 변경으로, 잠시 fade out
  const handleButton = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { id } = e.currentTarget
    setActiveButton(id)
    onInitPagination()
  }
  const handlePagination = (num: string) => {
    const page = Number(num)

    setPage(page)
    setStart((page - 1) * ITEM_LIMIT)
    setEnd((page - 1) * ITEM_LIMIT + ITEM_LIMIT)
    handleScrollMove()
  }

  if (!recruitItems[0].id)
    return <p className="text-red-500">CMS 데이터를 확인해주세요 :(</p>
  return (
    <SectionWrapper
      id="recruit-now"
      className={classNames(styles.RecruitNow, 'bg-white')}
    >
      <SectionHeader className="flex-column">
        <h1 ref={headerRef} className="mb-3">
          지금 채용중 !
        </h1>
        <h6 className="text-gray-600">
          스쿼드 • 팀별로 채용 중인 포지션을 확인해 보세요.
        </h6>
      </SectionHeader>
      {/*
      // NOTE: 공채 기간에는 공채 배너를 보여줌
      <div className={styles.RecruitNow__banner}>
        <h2>첫 번째 공채 구르미가 되어 보세요.</h2>
        <Responsive
          def={
            <Button
              size="xl"
              color="secondary"
              href="https://goormees.career.greetinghr.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classNames("d-flex align-items-center justify-content-center", styles.RecruitNow__button)}
            >
              공채 직군 보기
              <SubmitModeIcon className={styles.RecruitNow__button__icon} />
            </Button>
          }
          sm={
            <Button
              size="lg"
              color="secondary"
              href="https://goormees.career.greetinghr.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classNames("d-flex align-items-center justify-content-center", styles.RecruitNow__button)}
            >
              공채 직군 보기
              <SubmitModeIcon className={styles.RecruitNow__button__icon} />
            </Button>
          }
        />
      </div> */}
      <aside>
        <Nav
          tabs
          className={classNames(
            styles.RecruitNow__nav,
            'justify-content-between',
          )}
        >
          <NavItem className={classNames(styles.RecruitNow__navItem, 'd-flex')}>
            {teamItems.map(item => (
              <NavLink
                key={item[0]}
                id={item[0]}
                active={activeTab === item[0]}
                onClick={handleTab}
              >
                {item[0]}{' '}
                <Badge className={classNames(styles.RecruitNow__badge, 'ml-1')}>
                  {item[1].num}
                </Badge>
              </NavLink>
            ))}
          </NavItem>

          <span
            className={classNames(
              styles.RecruitNow__select,
              'd-none d-xl-flex align-items-center',
            )}
          >
            <Input
              type="select"
              name="select"
              id="goorm employment select"
              className="form-control-lg"
              onChange={handleSelect}
            >
              {employmentItems.map(item => (
                <option key={item} value={item}>
                  {EMPLOYMENT_KO[item]}
                </option>
              ))}
            </Input>
          </span>
        </Nav>
        {
          // NOTE: 회사 정책 변경으로, 잠시 fade out
          //   <aside className={styles.RecruitNow__aside}>
          //   <div
          //     className={classNames(
          //       styles.RecruitNow__asideWrapper,
          //       'd-flex mt-4',
          //     )}
          //   >
          //     {squadItems.map(item => (
          //       <Button
          //         key={item}
          //         id={item}
          //         color="link"
          //         size="md"
          //         active={activeButton === item}
          //         onClick={handleButton}
          //         className={styles.RecruitNow__asideBtn}
          //       >
          //         {item}
          //       </Button>
          //     ))}
          //   </div>
          // </aside>
        }
      </aside>
      <article>
        <RecruitNowItems start={start} end={end} list={filterList} />
      </article>

      <footer
        className={classNames('justify-content-center mt-5', {
          'd-none': Math.ceil(total / ITEM_LIMIT) === 1,
          'd-flex': Math.ceil(total / ITEM_LIMIT) !== 1,
        })}
      >
        <BasicPagination
          size="md"
          page={page}
          pageCount={Math.ceil(total / ITEM_LIMIT)}
          limitCount={ITEM_LIMIT}
          onPageChangeHandler={handlePagination}
          scrollMove={false}
          className={styles.RecruitNow__pagination}
        />
      </footer>
    </SectionWrapper>
  )
}

export default React.memo(RecruitNow)
