import React, { forwardRef } from 'react'
import { SquadType } from './components/Slide/SquadSlide.types'
import * as styles from './GoormStory.module.scss'
import { Container } from '@goorm-dev/components'
import squadSlideInfo from './components/Slide/SquadSlide.module'
import { TabCarousel } from './components/TabsCarousel/TabCarousel'
import { Slide } from './components/Slide/Slide'
// import { Carousel } from '_components/Common/Carousel/Carousel';

const GoormStory: React.FC = (_, ref) => {
  const squadList: SquadType[] = [
    'PLATFORM BIZ',
    'EDU BIZ',
    'EDU PUBLIC',
    'EDU CHANNEL',
    'DEVTH',
    'IDE',
  ]
  const slideList: React.ReactNode[] = squadList.map((squad, index) => {
    const dark = index % 2 ? true : false
    return (
      <Slide
        squad={squadSlideInfo[squad].squad}
        image={squadSlideInfo[squad].image}
        squadCards={squadSlideInfo[squad].squadCards}
        dark={dark}
        key={`slide-list-${index}`}
      />
    )
  })

  return (
    <div ref={ref} className={styles.Outer}>
      <Container fluid="lg" className={styles.GoormStory}>
        <div className={styles.GoormStory__header}>
          <h1>구르미 이야기</h1>
          <h2>
            비즈니스와 제품을
            <br />
            책임지는 디비전과 스쿼드
          </h2>
        </div>
        <div>
          <TabCarousel tabs={squadList}>{slideList}</TabCarousel>
        </div>
      </Container>
    </div>
  )
}

export default forwardRef(GoormStory)
