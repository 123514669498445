export const GOORMEE_INTERVIEW_LIST = [
  {
    id: '1',
    text: '꼬리에 꼬리를 무는\n인턴 이야기',
    href: 'https://blog.goorm.io/ictinterview1/',
    src: '/assets/recruit-goormee-ict.svg',
    backgroundColor: '#2DBD9C',
  },
  {
    id: '2',
    text: '프로덕트 디자이너\nCyan의 일기',
    href: 'https://blog.goorm.io/goorm_newbie_cyan_diary_1/',
    src: '/assets/recruit-goormee-cyan.svg',
    backgroundColor: '#1EBAD2',
  },
  {
    id: '3',
    text: '구름의 문화를 만들고 있는\nBella의 이야기',
    href: 'https://blog.goorm.io/interview_with_bella/',
    src: '/assets/recruit-goormee-bella.svg',
    backgroundColor: '#9C7FF5',
  },
  {
    id: '4',
    text: '구름 UX Writing 가이드라인\n제작기',
    href: 'https://blog.goorm.io/ux-writing/',
    src: '/assets/recruit-goormee-didi.svg',
    backgroundColor: '#EB9300',
  },
]
