import React, {
  FunctionComponent,
  useEffect,
  useState,
  forwardRef,
  memo,
} from 'react'
import _cloneDeep from 'lodash/cloneDeep'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import GoormeeCarousel from '_components/Recruit/GoormeeCarousel/GoormeeCarousel'
import useGridBreakpoint from '_hooks/useGridBreakpoint'

import { GOORMEE_INTERVIEW_LIST as data } from '_constants/goormeeList'

import * as styles from './GoormeeNews.module.scss'

enum CAROUSEL_CNT {
  xs = 1,
  sm = 2,
  md = 3,
  lg = 3,
  xl = 4,
}

const GoormeeNews: FunctionComponent = function (_, ref) {
  const currentBreakpoint: string = useGridBreakpoint([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ])
  const [list, setList] = useState([])

  useEffect(() => {
    const carouselCnt = CAROUSEL_CNT[currentBreakpoint]

    const arr = _cloneDeep(data)
    const len = arr.length
    const cnt =
      Math.floor(len / carouselCnt) +
      (Math.floor(len % carouselCnt) > 0 ? 1 : 0)
    const newArr = []

    for (let i = 0; i < cnt; i++) {
      newArr.push(arr.splice(0, carouselCnt))
    }

    setList(newArr)
  }, [currentBreakpoint])

  return (
    <SectionWrapper ref={ref} className={styles.GoormeeNews}>
      <SectionHeader>
        <h1 className="text-center text-white">더 많은 구르미 소식</h1>
      </SectionHeader>

      <article className={styles.GoormeeNews__article}>
        <GoormeeCarousel list={list} currentBreakpoint={currentBreakpoint} />
      </article>
    </SectionWrapper>
  )
}

export default memo(forwardRef(GoormeeNews))
