import React, { useState } from 'react'
import cx from 'classnames'
import * as styles from './Carousel.module.scss'
import {
  CarouselIndicators as RSCarouselIndicators,
  CarouselItem as RSCarouselItem,
  CarouselProps as RSCarouselProps,
  Carousel as RSCarousel,
} from 'reactstrap'
import { ChildrenClassNameProps } from '_types/ChildrenClassNameProps.types'

export const Carousel: React.FC<
  ChildrenClassNameProps &
    Omit<RSCarouselProps, 'next' | 'previous'> & {
      indicatorClassName?: string
    }
> = ({ className, children, indicatorClassName, ...options }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const items = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const elementChild: React.ReactElement = child

      return elementChild
    }
    return <></>
  })

  if (items === null || items === undefined) {
    console.error('올바르지 않은 children 입니다.')
    return <></>
  }

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: any) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item, index) => {
    return (
      <RSCarouselItem
        key={index}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        {item}
      </RSCarouselItem>
    )
  })

  const craouselClasses = cx([styles.Carousel, className])
  return (
    <RSCarousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className={craouselClasses}
      {...options}
    >
      <RSCarouselIndicators
        className={indicatorClassName}
        key={activeIndex}
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
    </RSCarousel>
  )
}
