import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { throttle } from 'lodash';

import * as styles from './CardSlider.module.scss';
import { TeamnameType } from '../../CardSlider.types';
import { cardSliderInfo } from '../../CardSlider.module';
import { Card } from '../Card/Card';
import CardScrollBar from '_components/Common/CardScrollBar/CardScrollBar';

type CardSliderProps = {
	className?: string;
	style?: React.CSSProperties;
};

export const CardSlider: React.FC<CardSliderProps> = ({ className, style, ref: cardListRef,  onScroll: cardScrollHandler }) => {
	const teamList: TeamnameType[] = [
		'DEVOPS',
		'DESIGN',
		'COMMUNICATION',
		'BIZOPS',
		'CONTENTS',
		'OPERATION',
	];

	const cards = teamList.map((team, idx) => {
		const cardInfo = cardSliderInfo[team];
		return <Card key={idx} {...cardInfo} />;
	});

	return (
		<div className={cx(styles.CardSlider, className)} style={style}>
			<CardScrollBar>
				<div className={styles.CardSlider__wrapper} onScroll={cardScrollHandler} ref={cardListRef}>
					{cards}
				</div>
			</CardScrollBar>
		</div>
	);
};