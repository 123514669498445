import * as React from 'react'
import cx from 'classnames'
import * as styles from './TabCarousel.module.scss'
import { useState } from 'react'
import {
  CarouselItem as RSCarouselItem,
  Carousel as RSCarousel,
  CarouselIndicatorsProps as RSCarouselIndicatorsProps,
} from 'reactstrap'
import { ChildrenClassNameProps } from '_types/ChildrenClassNameProps.types'
import { Nav, NavItem } from '../Nav/Nav'
import { SquadType } from '../Slide/SquadSlide.types'

type CaoruselIndicatorsProps = {
  className?: string
  children: React.ReactNode
  activeClassName?: string
} & Omit<RSCarouselIndicatorsProps, 'items'>

const CarouselIndicators: React.FC<CaoruselIndicatorsProps> = ({
  activeIndex,
  activeClassName,
  className,
  children,
  onClickHandler = () => {},
}) => {
  const items = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      // const elementChild: React.ReactElement = child;
      const injectedHandlerChild = React.cloneElement(child, {
        onClick: function onClick(e: React.MouseEventHandler) {
          // e.preventDefault();
          onClickHandler(index)
        },
        key: `carousel-indicator-items${index}`,
      })
      return injectedHandlerChild
    }
    return <></>
  })

  if (items === null || items === undefined) {
    console.error('올바르지 않은 children 입니다.')
    return <></>
  }

  const indicators = items.map((item, index) => {
    if (activeIndex === index) {
      return React.cloneElement(item, {
        className: cx([activeClassName, 'active']),
        key: '' + (item.key || Object.values(item).join('')),
      })
    }

    return item
  })

  const listClasses = cx(className, 'position-relative')
  return <Nav className={listClasses}>{indicators}</Nav>
}

type TabCarouselProps = {
  tabs?: SquadType[]
}

export const TabCarousel: React.FC<
  ChildrenClassNameProps & TabCarouselProps
> = ({ className, children, tabs }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const items = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const elementChild: React.ReactElement = child

      return elementChild
    }
    return <></>
  })

  if (items === null || items === undefined) {
    console.error('올바르지 않은 children 입니다.')
    return <></>
  }

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: any) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item, index) => {
    return (
      <RSCarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`slide-${index}`}
      >
        {item}
      </RSCarouselItem>
    )
  })

  const craouselClasses = cx([styles.TabCarousel, className])
  return (
    <RSCarousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className={craouselClasses}
      interval={false}
      enableTouch={false}
      keyboard={false}
    >
      <CarouselIndicators
        className={styles.TabCarousel__indicators}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      >
        {tabs?.map((tabName, index) => (
          <NavItem key={`nav-item-${index}`} squad={tabName} />
        ))}
      </CarouselIndicators>
      {slides}
    </RSCarousel>
  )
}
