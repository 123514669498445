import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Container, Row, Col } from '@goorm-dev/components'

import ListGroupItem from '_components/Common/ListGroupItem/ListGroupItem'
import ListGroup from '_components/Common/ListGroup/ListGroup'
import { RecruitNowItemType } from '_types/Recruit.types'
import { EMPLOYMENT_KO } from '_containers/Recruit/RecruitNow'

import * as styles from './RecruitNowItems.module.scss'

type RecruitHashtagType = {
  hashtag: string
}
type RecruitNowItemsProps = {
  list: RecruitNowItemType[]
  start: number
  end: number
}

const RecruitHashtag: FunctionComponent<RecruitHashtagType> = function ({
  hashtag,
}) {
  if (!hashtag)
    return <p className="text-red-500">CMS 데이터를 확인해주세요 :(</p>

  return (
    <>
      {hashtag.split(',').map((item, idx) => (
        <p key={idx} className={styles.RecruitNowItems__hashtag}>
          #{item}
        </p>
      ))}
    </>
  )
}
const RecruitNowItems: FunctionComponent<RecruitNowItemsProps> = function ({
  list,
  start,
  end,
}) {
  if (!list.length)
    return (
      <h4 className="text-center mt-5">현재 채용 중인 리스트가 없습니다 :(</h4>
    )
  return (
    <ListGroup className={styles.RecruitNowItems}>
      {list.slice(start, end).map(item => (
        <ListGroupItem key={item.id}>
          <a href={item.link}>
            <Container fluid="lg" className="px-0">
              <Row>
                <Col xl="5" md="4" xs="7">
                  <h4>{item.title}</h4>
                </Col>
                <Col
                  md="4"
                  className={classNames(
                    'd-none d-md-flex align-items-center',
                    styles.RecruitNowItems__hashtags,
                  )}
                >
                  <RecruitHashtag hashtag={item?.hashtag} />
                </Col>
                <Col xl="1" md="2" xs="2" className="d-flex align-items-center">
                  <p>{EMPLOYMENT_KO[item.employment]}</p>
                </Col>
                <Col
                  md="2"
                  xs="3"
                  className="d-flex align-items-center justify-content-end"
                >
                  <p>{item.team}</p>
                </Col>
              </Row>
            </Container>
          </a>
        </ListGroupItem>
      ))}
    </ListGroup>
  )
}

export default RecruitNowItems
