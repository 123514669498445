// extracted by mini-css-extract-plugin
export var GoormeeCarousel = "GoormeeCarousel-module--GoormeeCarousel--01560";
export var GoormeeCarousel__carousel = "GoormeeCarousel-module--GoormeeCarousel__carousel--c404d";
export var GoormeeCarousel__carouselItem = "GoormeeCarousel-module--GoormeeCarousel__carouselItem--4023f";
export var GoormeeCarousel__col = "GoormeeCarousel-module--GoormeeCarousel__col--76aec";
export var GoormeeCarousel__controlBtn = "GoormeeCarousel-module--GoormeeCarousel__controlBtn--46442";
export var GoormeeCarousel__controlBtnLeft = "GoormeeCarousel-module--GoormeeCarousel__controlBtn--left--5dc8f";
export var GoormeeCarousel__controlBtnMobileWrapper = "GoormeeCarousel-module--GoormeeCarousel__controlBtnMobileWrapper--e5930";
export var GoormeeCarousel__controlBtnRight = "GoormeeCarousel-module--GoormeeCarousel__controlBtn--right--af48b";
export var GoormeeCarousel__row = "GoormeeCarousel-module--GoormeeCarousel__row--b82b3";
export var cardFadeIn = "GoormeeCarousel-module--cardFadeIn--826ff";