import React, { FunctionComponent } from 'react'

type SplitTextProps = {
  text: string
  className?: string
}

const SplitText: FunctionComponent<SplitTextProps> = function ({
  text,
  className,
}) {
  return (
    <>
      {text
        .replace(/\\n/gi, '\n')
        .split('\n')
        .map((line, idx) => {
          return (
            <p key={idx} className={className}>
              {line}
              <br />
            </p>
          )
        })}
    </>
  )
}

SplitText.defaultProps = {
  className: '',
}

export default SplitText
