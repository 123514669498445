import * as React from 'react'
import cx from 'classnames'
import {
  Nav as RSNav,
  NavItem as RSNavItem,
  NavLink as RSNavLink,
} from 'reactstrap'
import {
  SquadType,
  SquadCardType,
  SquadSlideType,
} from '../Slide/SquadSlide.types'
import * as styles from './Nav.module.scss'
import { Button } from '@goorm-dev/components'

export const SelectedNavItem: React.FC<{
  squad: SquadType
  onClick: Function
  children?: React.ReactNode
}> = ({ squad, onClick, children = squad }) => {
  return (
    <RSNavLink
      className={styles.NavBar__link}
      onClick={() => {
        onClick()
      }}
    >
      <Button
        color="link"
        className={cx(['btn-outline-primary', styles.NavBar__selected])}
      >
        {children}
      </Button>
    </RSNavLink>
  )
}

export const NavItem: React.FC<{
  onClick?: Function
  squad: SquadType
  children?: React.ReactNode
  className?: string
}> = ({ onClick = () => {}, squad, children = squad, className }) => {
  return (
    <RSNavLink
      className={cx([styles.NavBar__link, className])}
      href="#"
      onClick={() => {
        onClick()
      }}
    >
      <RSNavItem>{children}</RSNavItem>
    </RSNavLink>
  )
}

export const Nav = RSNav
