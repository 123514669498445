// extracted by mini-css-extract-plugin
export var ResonForJoin = "ResonForJoin-module--ResonForJoin--1c972";
export var ResonForJoin__carousel = "ResonForJoin-module--ResonForJoin__carousel--41fab";
export var ResonForJoin__controlBtn = "ResonForJoin-module--ResonForJoin__controlBtn--16289";
export var ResonForJoin__controlBtnLeft = "ResonForJoin-module--ResonForJoin__controlBtn--left--99164";
export var ResonForJoin__controlBtnMobileWrapper = "ResonForJoin-module--ResonForJoin__controlBtnMobileWrapper--a8351";
export var ResonForJoin__controlBtnRight = "ResonForJoin-module--ResonForJoin__controlBtn--right--8a2c2";
export var ResonForJoin__desc = "ResonForJoin-module--ResonForJoin__desc--58e15";
export var ResonForJoin__indicators = "ResonForJoin-module--ResonForJoin__indicators--687aa";
export var ResonForJoin__item = "ResonForJoin-module--ResonForJoin__item--6f00b";
export var ResonForJoin__itemImage = "ResonForJoin-module--ResonForJoin__itemImage--f914c";
export var cardFadeIn = "ResonForJoin-module--cardFadeIn--a5727";