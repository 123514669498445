import React, { useState } from 'react'
import cx from 'classnames'
import * as styles from './SqaudCarousel.module.scss'
import { SquadSlideType, SquadCardType } from '../Slide/SquadSlide.types'
import Fade from '_components/Common/Fade/Fade'
import {
  CarouselIndicators as RSCarouselIndicators,
  CarouselItem as RSCarouselItem,
  CarouselProps as RSCarouselProps,
  Carousel as RSCarousel,
} from 'reactstrap'
import { ChildrenClassNameProps } from '_types/ChildrenClassNameProps.types'
import { Button } from '@goorm-dev/components'
import Responsive from '_components/Common/Responsive/Responsive'
import ChevronDownIcon from '@goorm-dev/components/build/ChevronDownIcon'
import ChevronUpIcon from '@goorm-dev/components/build/ChevronUpIcon'

type SqaudCardProps = SquadCardType & {
  className?: string
}

type DesctopCardProps = SqaudCardProps & {
  next: Function
  previous: Function
}

export const SqaudCard: React.FC<DesctopCardProps> = ({
  header = 'Header',
  desc = 'Desc',
  image,
  next,
  previous,
  className,
}) => {
  const cardClasses = cx([styles.Card, className])
  const imageClasses = cx([styles.Card__image, 'd-flex', 'align-items-center'])
  return (
    <div className={cardClasses}>
      <div className={imageClasses}>
        <img src={image} alt={'Card image'} />
      </div>
      <Fade
        distance="20px"
        durationFunc="cubic-bezier(.03,.38,.56,1.02)"
        duration="0.8s"
        className={cx(styles.Fade__header, 'h-100')}
      >
        <header className={styles.Card__header}>
          <h1 style={{ whiteSpace: 'pre-wrap' }}>
            <>{header}</>
          </h1>
        </header>
      </Fade>
      <div className={styles.Card__desc}>
        <Fade
          distance="40px"
          durationFunc="cubic-bezier(.03,.38,.56,1.02)"
          duration="0.8s"
          className={styles.Fade__desc}
        >
          <p>
            <>{desc}</>
          </p>
        </Fade>
        <div>
          <Button
            size={'lg'}
            className={cx(styles.Card__chevronButton, 'mr-2')}
            outline
            color={'basic'}
            onClick={() => {
              previous()
            }}
          >
            <ChevronUpIcon />
          </Button>
          <Button
            size={'lg'}
            className={cx(styles.Card__chevronButton, 'mr-2')}
            outline
            color={'basic'}
            onClick={() => {
              next()
            }}
          >
            <ChevronDownIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}

const CurrentIndex: React.FC<{
  current: number | string
  max: number | string
}> = ({ current, max }) => {
  return <p className={styles.Card__currentIndex}>{`${+current + 1}/${max}`}</p>
}

type MobileSqaudCardProps = SqaudCardProps

export const MobileSqaudCard: React.FC<MobileSqaudCardProps> = ({
  header = 'Header',
  desc = 'Desc',
  image,
  className,
}) => {
  const cardClasses = cx([styles.Card, className])
  const imageClasses = cx([styles.Card__image, 'd-flex', 'align-items-center'])
  return (
    <div className={cardClasses}>
      <div className={imageClasses}>
        <img src={image} alt={'Card image'} />
      </div>
      <Fade
        distance="20px"
        durationFunc="cubic-bezier(.03,.38,.56,1.02)"
        duration="0.8s"
        className={styles.Fade__header}
      >
        <header className={styles.Card__header}>
          <h1 style={{ whiteSpace: 'pre-wrap' }}>
            <>{header}</>
          </h1>
        </header>
      </Fade>
      <div className={styles.Card__desc}>
        <Fade
          distance="40px"
          durationFunc="cubic-bezier(.03,.38,.56,1.02)"
          duration="0.8s"
          className={styles.Fade__desc}
        >
          <p>
            <>{desc}</>
          </p>
        </Fade>
      </div>
    </div>
  )
}

type SqaudCarouselType = ChildrenClassNameProps &
  Omit<RSCarouselProps, 'next' | 'previous'> & {
    indicatorClassName?: string
    squadCards: Array<SquadCardType>
    image: string
  }

export const SquadCarousel: React.FC<SqaudCarouselType> = ({
  className,
  children,
  indicatorClassName,
  squadCards,
  image,
  ...options
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: any) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const items: Array<JSX.Element> = squadCards.map((card, index) => (
    <Responsive
      key={`responsive-${card.header}-${index}`}
      def={
        <SqaudCard
          header={card.header}
          desc={card.desc}
          image={image}
          key={`card-${card.header}-${index}`}
          next={next}
          previous={previous}
        />
      }
      sm={
        <MobileSqaudCard
          header={card.header}
          desc={card.desc}
          image={image}
          key={`card-${card.header}-${index}`}
        />
      }
    />
  ))

  const slides = items.map((item, index) => {
    return (
      <RSCarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`slide-${index}`}
      >
        {item}
      </RSCarouselItem>
    )
  })

  const craouselClasses = cx([styles.SqaudCarousel, className])
  const indicatorClasses = cx(indicatorClassName, styles.Card__indicator)
  return (
    <div className="position-relative">
      <RSCarousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        className={craouselClasses}
        {...options}
      >
        <RSCarouselIndicators
          className={indicatorClasses}
          key={activeIndex}
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
      </RSCarousel>
      <Responsive
        key={`responsive-${image}-${activeIndex}`}
        def={<div key={`div-${image}-${activeIndex}`}></div>}
        sm={
          <CurrentIndex
            key={`currentIndex-${image}-${activeIndex}`}
            current={activeIndex}
            max={items.length}
          />
        }
      />
    </div>
  )
}
