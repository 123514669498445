import React, { useState, FunctionComponent, useEffect } from 'react'
import classNames from 'classnames'
import { Collapse } from '@goorm-dev/components'
import IconUp from '@goorm-dev/components/build/ChevronUpIcon'

import ListGroup from '_components/Common/ListGroup/ListGroup'
import ListGroupItem from '_components/Common/ListGroupItem/ListGroupItem'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import SplitText from '_components/Common/SplitText/SplitText'
import SectionHeader from '_components/Common/SectionHeader/SectionHeader'

import { RecruitFaqNodeType } from '_types/Recruit.types'

import * as styles from './RecruitFaq.module.scss'

type RecruitFaqProps = {
  recruitFaqList: RecruitFaqNodeType[]
}
type RecruitFaqListType = {
  [key: string]: {
    date: string
    question: string
    answer: string
    isOpen: boolean
  }
}

const RecruitFaq: FunctionComponent<RecruitFaqProps> = function ({
  recruitFaqList,
}) {
  const [list, setList] = useState<RecruitFaqListType>({
    id: { question: '', answer: '', date: '', isOpen: false },
  })

  useEffect(() => {
    const obj: RecruitFaqListType = {}
    recruitFaqList.forEach(({ node: { id, date, question, answerObj } }) => {
      const answer: string = answerObj?.data?.text || ''
      obj[id] = { question, answer, date, isOpen: false }
    })

    setList(obj)
  }, [])

  const handleCollapse = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = e.currentTarget

    setList({
      ...list,
      [id]: { ...list[id], isOpen: !list[id].isOpen },
    })
  }

  if (!Object.keys(list).length) return null

  return (
    <SectionWrapper className={classNames(styles.RecruitFaq, 'bg-gray-50')}>
      <header>
        <SectionHeader>
          <h1>채용 문의</h1>
        </SectionHeader>
      </header>
      <section>
        <ListGroup>
          {Object.keys(list).map(item => (
            <ListGroupItem key={item} className="bg-gray-50">
              <header
                id={item}
                className={classNames(
                  'd-flex align-items-center justify-content-between',
                  styles.RecruitFaq__question,
                )}
                onClick={handleCollapse}
              >
                <h5>Q. {list[item]?.question}</h5>

                <IconUp
                  width={24}
                  height={24}
                  className={classNames(styles.RecruitFaq__icon, {
                    [styles.RecruitFaq__iconOpend]: !list[item]?.isOpen,
                  })}
                />
              </header>

              <article className="px-4 text-gray-700">
                <Collapse isOpen={list[item]?.isOpen}>
                  <div className="mt-3">
                    {list[item]?.answer ? (
                      <SplitText text={list[item]?.answer} />
                    ) : (
                      <p className="text-red-500">
                        CMS 데이터를 확인해주세요 :(
                      </p>
                    )}
                  </div>
                </Collapse>
              </article>
            </ListGroupItem>
          ))}
        </ListGroup>
      </section>
    </SectionWrapper>
  )
}

export default RecruitFaq
