import * as React from 'react'
import cx from 'classnames'
import * as styles from './Card.module.scss'
import Fade from '_components/Common/Fade/Fade'
import { Badge } from '@goorm-dev/components'
import { ChildrenClassNameProps } from '_types/ChildrenClassNameProps.types'
import ChevronRightIcon from '../../../../../images/Recruit/chevron-right-icon.svg'
import CloseIcon from '../../../../../images/Recruit/close-icon.svg'
import { TeamInfoType } from '../../CardSlider.types'

type CardProps = TeamInfoType & ChildrenClassNameProps

export const Card: React.FC<CardProps> = ({
  teamname = 'DEVOPS',
  header = 'header',
  content = <p>Hello</p>,
  image,
  className,
}) => {
  const [isActive, setIsActive] = React.useState<boolean>(false)
  const cardRef = React.useRef<HTMLInputElement>(null)

  const handleClose = (event: React.MouseEvent<HTMLImageElement>) => {
    if (cardRef.current) {
      event.preventDefault()
      setIsActive(false)
      cardRef.current.blur()
    }
  }

  const Body: React.FC = () => {
    if (isActive) {
      return (
        <>
          <div className={styles.Card__info}>
            <img
              src={CloseIcon}
              onClick={handleClose}
              className={styles.Card__close}
            />
            <Fade distance="20px" durationFunc="ease-out" freezeOnceVisible>
              <div className={styles.Card__teamnameActive}>
                <Badge size="md" color="light">
                  {teamname}
                </Badge>
              </div>
              <header className={styles.Card__headerActive}>
                <h3>{header}</h3>
              </header>
              <article className={styles.Card__contentActive}>
                {content}
              </article>
            </Fade>
          </div>
        </>
      )
    } else {
      return (
        <>
          <header className={cx([styles.Card__header, 'mb-2'])}>
            <h2>{teamname}</h2>
          </header>
          <div>
            <img src={ChevronRightIcon}></img>
          </div>
        </>
      )
    }
  }

  const backgroundActiveOptions = isActive && {
    filter: 'blur(26px) brightness(0.7)',
    transform: 'scale(1.2)',
  }
  const backgroundStyle = {
    backgroundImage: `URL(${image})`,
    ...backgroundActiveOptions,
  }

  return (
    <div
      className={cx([className, styles.Card])}
      ref={cardRef}
      onMouseEnter={() => {
        setIsActive(true)
      }}
      onMouseLeave={() => {
        setIsActive(false)
      }}
      onBlur={() => {
        setIsActive(false)
      }}
      onClick={() => {
        if (!isActive) setIsActive(true)
      }}
    >
      <div className={styles.Card__image} style={backgroundStyle} />
      <Body />
    </div>
  )
}
