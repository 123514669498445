import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames'
import { Carousel, CarouselItem, Button, Row, Col } from '@goorm-dev/components'
import ChevronLeftIcon from '@goorm-dev/components/build/ChevronLeftIcon'
import ChevronRightIcon from '@goorm-dev/components/build/ChevronRightIcon'

import GoormeeCard from '_components/Recruit/GoormeeCard/GoormeeCard'

import * as styles from './GoormeeCarousel.module.scss'

type GoormeeCarouselProps = {
  // TODO: list type 수정하기
  list: []
  currentBreakpoint: string
}
type CarouselButtonsProps = {
  activeIndex: number
  listLength: number
  handlePrevious: React.MouseEventHandler<HTMLButtonElement>
  handleNext: React.MouseEventHandler<HTMLButtonElement>
}

const CarouselButtons: FunctionComponent<CarouselButtonsProps> = function ({
  activeIndex,
  listLength,
  handlePrevious,
  handleNext,
}) {
  return (
    <>
      {/* 웹뷰 버튼 */}
      <div className="d-lg-block d-none">
        <Button
          size="xl"
          color="light"
          outline
          className={classNames(
            styles.GoormeeCarousel__controlBtn,
            styles.GoormeeCarousel__controlBtnLeft,
          )}
          onClick={handlePrevious}
        >
          <ChevronLeftIcon width={24} height={24} />
        </Button>
        <Button
          size="xl"
          color="light"
          outline
          className={classNames(
            styles.GoormeeCarousel__controlBtn,
            styles.GoormeeCarousel__controlBtnRight,
          )}
          onClick={handleNext}
        >
          <ChevronRightIcon width={24} height={24} />
        </Button>
      </div>

      {/* 모바일뷰 버튼 */}
      <div
        className={classNames(
          'd-lg-none d-flex align-items-center',
          styles.GoormeeCarousel__controlBtnMobileWrapper,
        )}
      >
        <Button
          size="lg"
          color="light"
          outline
          className={classNames(
            styles.GoormeeCarousel__controlBtn,
            styles.GoormeeCarousel__controlBtnLeft,
          )}
          onClick={handlePrevious}
        >
          <ChevronLeftIcon width={24} height={24} />
        </Button>
        <p className="subtitle-1 text-gray-600">
          {activeIndex + 1} / {listLength}
        </p>
        <Button
          size="lg"
          color="light"
          outline
          className={classNames(
            styles.GoormeeCarousel__controlBtn,
            styles.GoormeeCarousel__controlBtnRight,
          )}
          onClick={handleNext}
        >
          <ChevronRightIcon width={24} height={24} />
        </Button>
      </div>
    </>
  )
}

const GoormeeCarousel: FunctionComponent<GoormeeCarouselProps> = function ({
  list,
  currentBreakpoint,
}) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const handleNext = () => {
    if (animating) return
    const nextIndex = activeIndex === list.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }
  const handlePrevious = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? list.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  return (
    <div className={classNames(styles.GoormeeCarousel, 'px-0')}>
      <Row
        className={classNames(
          styles.GoormeeCarousel__row,
          'd-flex justify-content-center',
        )}
      >
        <Col xl="12" lg="10" xs="12" className={styles.GoormeeCarousel__col}>
          <Carousel
            activeIndex={activeIndex}
            next={handleNext}
            previous={handlePrevious}
            interval={false}
            className={styles.GoormeeCarousel__carousel}
          >
            {list.map((items, idx) => (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={idx}
              >
                <div className={styles.GoormeeCarousel__carouselItem}>
                  {items.map(item => (
                    <React.Fragment key={item.id}>
                      <GoormeeCard
                        href={item.href}
                        src={item.src}
                        text={item.text}
                        backgroundColor={item.backgroundColor}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </CarouselItem>
            ))}
            {currentBreakpoint !== 'xl' && (
              <CarouselButtons
                activeIndex={activeIndex}
                listLength={list.length}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            )}
          </Carousel>
          <p className="subtitle-1 text-gray-600 text-center mt-4 d-xl-none d-lg-block d-none">
            {activeIndex + 1} / {list.length}
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default GoormeeCarousel
