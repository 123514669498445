// extracted by mini-css-extract-plugin
export var Card = "SqaudCarousel-module--Card--67953";
export var Card__chevronButton = "SqaudCarousel-module--Card__chevronButton--b2678";
export var Card__currentIndex = "SqaudCarousel-module--Card__currentIndex--5ad69";
export var Card__desc = "SqaudCarousel-module--Card__desc--d1132";
export var Card__header = "SqaudCarousel-module--Card__header--92d11";
export var Card__image = "SqaudCarousel-module--Card__image--1b7fe";
export var Card__indicator = "SqaudCarousel-module--Card__indicator--97ab2";
export var Fade__desc = "SqaudCarousel-module--Fade__desc--698e7";
export var Fade__header = "SqaudCarousel-module--Fade__header--c3d84";
export var SqaudCarousel = "SqaudCarousel-module--SqaudCarousel--49973";
export var cardFadeIn = "SqaudCarousel-module--cardFadeIn--f996b";